import { useState } from 'react';
import {Button, Modal,Col, Row, InputGroup, Form} from 'react-bootstrap';
import  axios_instance from '../../components/api/axiosConfig'

function Product_add_popup(props) {
  
  const [showProdOne, setShowProdOne] = useState(false);
  const handleClose = () => setShowProdOne(false);
  const handleShow = () => setShowProdOne(true);
  const [validatedProdOne, setValidatedProdOne] = useState(false);
  const [addProdImage, setAddProdImage] = useState('');

  async function  handleSubmit(event){
    const formdataAddProd = new FormData()
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
        setValidatedProdOne(true);
      event.preventDefault();
      console.log(addProdImage);
      formdataAddProd.append('name',event.target.elements.name.value);
      formdataAddProd.append('description',event.target.elements.description.value);
      formdataAddProd.append('file',addProdImage);
      formdataAddProd.append('price',event.target.elements.price.value);
      formdataAddProd.append('producttype',event.target.elements.producttype.value);
      formdataAddProd.append('rating',event.target.elements.rating.value);
      formdataAddProd.append('discount',event.target.elements.discount.value);
      formdataAddProd.append('discount_percentage',event.target.elements.discount_percentage.value);
      formdataAddProd.append('product_storage',event.target.elements.product_storage.value);
      formdataAddProd.append('ram',event.target.elements.ram.value);
      formdataAddProd.append('os',event.target.elements.os.value);
      formdataAddProd.append('modal',event.target.elements.modal.value);
      try{ await axios_instance({
            url: "api/addproduct",
            method: "POST",
            data:formdataAddProd
        }).then( (res) =>{
            setShowProdOne(false)
        })
        window.location.reload()
      }
      catch(e){
          console.error(e)
      } 
    }

     
  };
  return (
    <>
      
      <Button size="sm" variant="outline-success" className="add_btn" onClick={handleShow} >+</Button>
      
      <Modal
        show={showProdOne}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      <Form noValidate validated={validatedProdOne} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03" className="mb-3">
                      <Form.Label>Item name</Form.Label>
                      <Form.Control type="text" name="name" placeholder="samsung, nokia, item" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                      <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Category</Form.Label>
                      <Form.Control type="text" name="description" placeholder="color, used..." required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row >
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Operating System</Form.Label>
                      <Form.Control type="text" name="os" placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Price</Form.Label>
                      <Form.Control type="text" name="price" placeholder="color, used..." required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Product type</Form.Label>
                      <Form.Control type="text" name="producttype" placeholder="Phone, accessory,earphone...." required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Rating</Form.Label>
                      <Form.Control type="text" name="rating" placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Discount percentage</Form.Label>
                      <Form.Control type="text" name="discount_percentage" placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Discount price</Form.Label>
                      <Form.Control type="text" name="discount" placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Storage</Form.Label>
                      <Form.Control type="text" name="product_storage" placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Ram</Form.Label>
                      <Form.Control type="text" name="ram" placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Product model</Form.Label>
                      <Form.Control type="text" name="modal" placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                  <Row className="mb-12">
                  <Form.Group as={Col} md="12" controlId="validationCustom03" >
                      <Form.Label>Photo</Form.Label>
                      <Form.Control type="file" name="file_path" placeholder="color, used..." required  onChange={(e)=>setAddProdImage(e.target.files[0])} />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" >Add</Button>
        </Modal.Footer>
        </Form>
      </Modal>
      
    </>
  );
}

export default Product_add_popup;