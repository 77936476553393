import React ,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import  Header from '../../components/Header'
import Breadcrum from '../../components/Breadcrum'
import Side_filtration from './Side_filtration'
import Store_list from './Store_list'
import Social_media from '../../components/Social_media'
import Footer from '../../components/Footer'
import  axios_instance from '../../components/api/axiosConfig'
function Login(){
  
  return(
    <div>
      <Header/>
      { (localStorage.getItem('user-info'))?<Breadcrum /> :null}
      
      { /* ASIDE */}
      <div class="section">
            { /* container */}
            <div class="container">
                { /* row */}
                <div class="row">
                    <Side_filtration />
                    <Store_list />
                </div> 
                { /* row */}
            </div>
            { /* container */}
      </div>
      { /* ASIDE */}
      <Social_media />
      <Footer />
     
    </div>
  )
}

export default Login
