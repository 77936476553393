const customStyles = {
    rows: {
        style: {
            minHeight: '40px', // override the row height
        },
    }/*,
    headCells: {
        style: {
            paddingLeft: '2px', // override the cell padding for head cells
            paddingRight: '2px',
            fontWeight:'bolder'
        },
    },
    cells: {
        style: {
            paddingLeft: '2px', // override the cell padding for data cells
            paddingRight: '2px',
        },
    },*/
};
export default customStyles