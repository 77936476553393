import { useState } from 'react';
import {Button, Modal,Col, Row, InputGroup, Form} from 'react-bootstrap';
import  axios_instance from '../../components/api/axiosConfig'

function Delete_popup(props) {
  
  const [showDelCat, setShowDelCat] = useState(false);
  const handleClose = () => setShowDelCat(false);
  const handleShow = () => setShowDelCat(true);
  const [validatedDelCat, setValidatedDelCat] = useState(false);
  const formdatadelCat = new FormData()

  async function  handleSubmit(event){
    
      event.preventDefault();
      event.stopPropagation();
    
      //formdataAddCat.append('name',event.target.elements.category_name.value)
      try{ await axios_instance({
            url: "api/cat_delete/"+props.id,
            method: "DELETE",
            //data:formdataAddCat
        }).then( (res) =>{
            setShowDelCat(false)
        })
          
      }
      catch(e){
          console.error(e)
      } 
    
  };
  return (
    <>
      
      
        <i className="fa fa-trash action_btn action_del" onClick={handleShow}  aria-hidden="true"></i>
      
      
      <Modal
        show={showDelCat}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      <Form noValidate validated={validatedDelCat} onSubmit={handleSubmit}>
        
        <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Are you sure?</Form.Label>
                      
                    </Form.Group>
                    
                   
                  </Row>
      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} >
            Close
          </Button>
          <Button variant="primary" type="submit" onClick={handleSubmit} >Delete</Button>
        </Modal.Footer>
        </Form>
      </Modal>
      
    </>
  );
}

export default Delete_popup;