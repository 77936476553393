import axios from 'axios';

 const axios_instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL ,
    headers: {
        /*Authorization: <token>,*/
        //"Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        timeout: 1000,
    },
});
export const main_url = process.env.REACT_APP_BASE_URL;
export default axios_instance;