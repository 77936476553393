import logo from './logo.svg';
import './components/styles/App.css';
import {Button} from 'react-bootstrap';

import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from './pages/login_report/Login'
import View_product from './pages/login_report/View_product'
import Product_list_home from './pages/product_report/Product_list_home'
import Protected from './Protected'
/*import Register from './Register'
import AddProduct from './AddProduct'
import UpdateProduct from './UpdateProduct'
import Protected from './Protected'
import ProductList from './ProductList'
*/
import Category_list_home from './pages/category_report/Category_list_home'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      
      <Routes>
      
      <Route path="/" element={<Login />} />
      {/*<Route path="/register" element={<Register />} />
      <Route path="/" element={<Protected Cmp={ProductList} />} />
      <Route path="/add" element={<Protected Cmp={AddProduct} />} />
      <Route path="/update/:id" element={<Protected Cmp={UpdateProduct} />} />
      */}
      <Route path="/category_list" element={<Protected Cmp={Category_list_home} />} />
      <Route path="/product_list" element={<Protected Cmp={Product_list_home} />} />
      <Route path="/view_product/:productId" element={<View_product/>} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
