import { useState,useEffect, useRef, useContext } from 'react';
import {Button, Modal,Col, Row, InputGroup, Form} from 'react-bootstrap';
import  axios_instance from '../../components/api/axiosConfig'
import {AppContextCat} from  './Category_list_home'

const Edit_popup =(props)=> {
   const { setUpdateCat } = useContext(AppContextCat);
  const [showCatEdit, setShowCatEdit] = useState(false);
  const handleClose = () => setShowCatEdit(false);
  
  const [validatedCat, setValidatedCat] = useState(false);
  const [dataEditCat, setDataEditCat] = useState([])
  /*const [nameEditCat,setNameEditCat] = useState('')*/
  const nameEditRefCat =useRef(null);
  const formdataEditCat = new FormData()
  const id = props.id

  const handleShow = () =>{
     getDataEditCat() 
     setShowCatEdit(true); 
     
  } 
         

    async function getDataEditCat(){
        try{
            await axios_instance({
                url:'api/cat_get/'+id,
                method:"get"
            }).then( (res)=>{
                //console.log(res.data)
                setDataEditCat(res.data)
                
            })
        } catch(e){
            console.log(e)
        }
        
    }
  async function  handleSubmit(event){
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
        setValidatedCat(true);
      event.preventDefault();
      
      /*formdataEditCat.append('name',event.target.elements.category_name.value)*/
      /*console.log(nameEditRefCat.current.value);*/
      formdataEditCat.append('name',nameEditRefCat.current.value)
      try{ await axios_instance({
            url: "api/cat_update/"+id,
            method: "POST",
            data:formdataEditCat
        }).then( (res) =>{
            
            setShowCatEdit(false)
            setUpdateCat(false)
            
        })
          
      }
      catch(e){
          console.error(e)
      } 
      
    }

    
  };
  return (
    <>
      
      
      <i className="fa fa-edit  action_btn action_edit " onClick={handleShow}></i>
      <Modal
        show={showCatEdit}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      <Form noValidate validated={validatedCat} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Category</Form.Label>
                      <Form.Control type="text" name="category_name" placeholder="Phone, accesories,Extensions" defaultValue={dataEditCat.name}   ref={nameEditRefCat} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit"  onClick={handleSubmit}>Edit</Button>
        </Modal.Footer>
        </Form>
      </Modal>
      
    </>
  );
}

export default Edit_popup;