import React ,{useState, useEffect} from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import  Header from '../../components/Header'
import Breadcrum from '../../components/Breadcrum'
import Social_media from '../../components/Social_media'
import Footer from '../../components/Footer'
import  axios_instance from '../../components/api/axiosConfig'
function View_product(){
  const viewPdtParams =useParams();
  const [dataViewPdt, setDataViewPdt] = useState([]);
  
    const viewProductDataId = viewPdtParams.productId;
   
    
    useEffect(() => {
       const getDataViewProduct = async (id)=> {

        try {
            await axios_instance({
                url: "api/getproduct/"+id,
                method: "GET",
            }).then((res) => {
                console.log(res.data)
                setDataViewPdt(res.data)
            })

        } catch (e) {
            console.error(e);
        }
        
    }
    getDataViewProduct(viewProductDataId);    
    }, [viewProductDataId])
    const image_path = process.env.REACT_APP_IMAGE_URL;
    
  return(
    <div>
      <Header/>
      { <Breadcrum />}
      
      { /* ASIDE */}
      <div className="section">
            { /* container */}
            <div className="container">
                { /* row */}
                <div className="row">
                    {/*<!-- Product main img --> */}
                    <div className="col-md-5 col-md-push-2">
                        <div id="product-main-img">
                            <div className="product-preview">
                          
                                <img src={image_path+dataViewPdt.file_path} alt="" />
                            </div>

                            
                        </div>
                    </div>
                    {/*<!-- /Product main img --> */}

                    {/*<!-- Product thumb imgs --> */}
                    <div className="col-md-2  col-md-pull-5">
                        <div id="product-imgs">
                            <div className="product-preview">
                                <img src={image_path+dataViewPdt.file_path} alt="" />
                            </div>

                            
                        </div>
                    </div>
                    {/*<!-- /Product thumb imgs --> */}

                    {/*<!-- Product details --> */}
                    <div className="col-md-5">
                        <div className="product-details">
                            <h2 className="product-name">{dataViewPdt.name}</h2>
                            
                            <div>
                                
                                <span className="product-available">In Stock</span>
                            </div>
                            <p>Description : {dataViewPdt.description}</p>
                            <p>Price :shs <h3 className="product-price"> {dataViewPdt.price} <del className="product-old-price">{dataViewPdt.discount}</del></h3></p>
                            <p>Rating  : {dataViewPdt.rating}</p>
                            <p>Discount : {dataViewPdt.discount}</p>
                            <p>Storage : {dataViewPdt.product_storage}</p>
                            <p>Ram : {dataViewPdt.ram}</p>
                            <p>Operating system : {dataViewPdt.os}</p>
                            <p>Make : {dataViewPdt.modal}</p>

                            <ul className="product-links">
                                <li>Category:</li>
                                <li>{dataViewPdt.producttype}</li>
                                
                            </ul>
                        </div>
                    </div>
                </div> 
                { /* row */}
            </div>
            { /* container */}
      </div>
      { /* ASIDE */}
      <Social_media />
      <Footer />
     
    </div>
  )
}

export default View_product
