import React ,{useState, useEffect, useMemo,createContext} from 'react';
import { Button, Form ,Row,Col,Container, InputGroup } from 'react-bootstrap';
import DataTable , { createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom'
import  Header from '../../components/Header'
//import Product from './Product'
import Breadcrum from '../../components/Breadcrum'
import Social_media from '../../components/Social_media'
import Footer from '../../components/Footer'
import  customStyles from '../../components/styles/Table_styles'
import  axios_instance from '../../components/api/axiosConfig'
import downloadCSV from '../../components/functionalities/Export'
import CustomLoader from '../../components/functionalities/CustomLoader'
import Add_popup from './Add_popup'
import Edit_popup from './Edit_popup'
import Delete_popup from './Delete_popup'
 
export const AppContextCat = createContext(null)
 
function Category_list_home(){
       
    const [pendingCat, setPendingCat] = useState(true);
    const [updateCat, setUpdateCat] = useState(true);
    const columns =[
     {
            name:'Actions',
            cell: (row: { id: any }) => (
            <>
            <span  > <AppContextCat.Provider value={setUpdateCat} ><Edit_popup name="Edit data" id={row.id} /></AppContextCat.Provider> </span>{'     '}
            <span  ><Delete_popup id={row.id} />
            </span>
            </>
        ),

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,

    },
    {
         name:'id',
         selector: row => row.id,
         sortable: true
     },
     {
         name:'Name',
         selector: row => row.name,
         sortable: true,
        /* right: true,*/
     },
        
     ];
     const [dataCat, setDataCat] = useState([]);
      const [filteredDataCat, setFilteredDataCat] = useState(dataCat); 
    
    



    async function getDataCat(){
       
        try{
            await axios_instance({
                url:'api/cat_list',
                method:"get"
            }).then( (res)=>{
                console.log(res.data)
                setDataCat(res.data);
                setFilteredDataCat(res.data);
                setPendingCat(false);
            })
        } catch(e){
            console.log(e)
        }
        
    }
    
     useEffect( ()=>{
            getDataCat()
            console.log("API CALLED")
        },[updateCat])
    function filterHandle(event){
        console.log(dataCat);
        console.log(dataCat);
         if(event.target.value){
            const newData = dataCat.filter(row => {
                return row.name.toLowerCase().includes(event.target.value.toLowerCase())
            });
            setFilteredDataCat(newData);
        }else{
            setFilteredDataCat(dataCat );
            console.log(dataCat);
        }
       
        
    }
    // CSV exporter
    const filename ='Category list.csv'
    const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="col-sm-4" size="sm">Export</Button>;
    const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(dataCat,filename)} />, []);
    
    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Container>
              <Row>
                <Col sm={2}><Add_popup name="Add category"/></Col>
                <Col md={{ span: 4, offset: 6 }}><Form.Control className="col-sm-4" size="sm" type="text" placeholder="search" onChange={filterHandle}  /></Col>
              </Row>
            </Container>
        );
    }, []);
  return(
    <div>
    
      <Header/>
      
      <Breadcrum />
      { /* ASIDE */}
      <div class="section">
            { /* container */}
            <div class="container">
                { /* row */}
                <div class="row">
                    <Row>
                       
                       
                        
                    </Row>
                    
                    <DataTable striped bordered hover
                    columns={columns} 
                    data={filteredDataCat}
                    title ="Category list"
                        selectableRows
                        fixedHeader="true"
                        fixedHeaderScrollHeight="500px"
                        pagination
                        actions={actionsMemo}
                        customStyles={customStyles}
                        progressPending={pendingCat}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
            
                        progressComponent={<CustomLoader />}
                    ></DataTable>
                    
                </div> 
                { /* row */}
            </div>
            { /* container */}
      </div>
      { /* ASIDE */}
      <Social_media />
      <Footer />
     
    </div>
    
  )
}

export default Category_list_home
