import React, { useState, useEffect } from 'react'
import axios_instance, { main_url } from '../../components/api/axiosConfig'
import { Link, useNavigate,useLocation  } from 'react-router-dom'
function Store_list() {
    const [data, setData] = useState([])
    const [dataSection, setDataSection] = useState([]);
    const [item_list_load, setItemListLoad] =useState(1);
   /* useEffect(() => {
        getData();
        console.log(recordsPerPage);
    }, [])

    async function getData() {
        
        try {
            await axios_instance({
                url: "api/productlist",
                method: "GET",
            }).then((res) => {
                console.log(res.data)
                setData(res.data)
                let result = res.data;
                setDataSection(result.slice(firstIndex, lastIndex))
                    //console.log(res)
                setCurrentPage(1)
            })

        } catch (e) {
            console.error(e);
        }
    }*/
    useEffect(() => {
        const getData = async ()=> {
        
        try {
            await axios_instance({
                url: "api/productlist",
                method: "GET",
            }).then((res) => {
                console.log(res.data)
                setData(res.data)
                let result = res.data;
                setDataSection(result.slice(firstIndex, lastIndex))
                    //console.log(res)
                setCurrentPage(1)
            })

        } catch (e) {
            console.error(e);
        }
    }
        getData();
        
    }, [item_list_load])
    //Pagnation
    function prePage(e) {
        e.preventDefault();
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1)
            let start_no = currentPage - 1;
            let end_no = currentPage + 8
            setDataSection(data.slice(start_no, end_no));

        }

    }

    function changePage(id, e) {
        e.preventDefault();
        setCurrentPage(id)
        getSplitData(id)

    }

    function getSplitData(id) {

        let start_no = 0;
        let end_no = (recordsPerPage);
        if (id > 1) {
            end_no = (id * recordsPerPage)
            start_no = (end_no - recordsPerPage);

        }
        setDataSection(data.slice(start_no, end_no));
        /*console.log("currentPage:"+currentPage);
        console.log(start_no +' to '+end_no );
        console.log('page no:'+ id );
        console.log(data.slice( start_no , end_no ));*/
    }

    function nextPage(e, pageno = currentPage) {
        e.preventDefault();
        if (currentPage < npage) {
            setCurrentPage(pageno + 1)
            let start_no = currentPage - 1;
            let end_no = currentPage + 8
            setDataSection(data.slice(start_no, end_no));

        }

    }

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(9);
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = data.slice(firstIndex, lastIndex);
    const npage = Math.ceil(data.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const image_path = process.env.REACT_APP_IMAGE_URL;
    console.log("url:" + main_url)
    return (

        <div id = "store"
        className = "col-md-9" > { /* STORE  */ } { /* store top filter  */ } 
        <div className = "store-filter clearfix hide-mobile" >
        <div className = "store-sort" > {
            /*<label>
                                                Sort By:
                                                <select className="input-select">
                                                    <option value="0">Popular</option>
                                                    <option value="1">Position</option>
                                                </select>
                                            </label> */
        }

        <label >
        Show:
        <select className = "input-select"
        onChange = {
            (e) => setRecordsPerPage(e.target.value)
        } >
        <
        option value = "9" > 9 < /option> <
        option value = "18" > 18 < /option> < /
        select > <
        /label> < /
        div > {
            /*<ul className="store-grid">
                                            <li className="active"><i className="fa fa-th"></i></li>
                                            <li><a href="#"><i className="fa fa-th-list"></i></a></li>
                                        </ul> */
        } </div>  
        {  /*        /store            top filter */
    }

    { /* store products  */ } <
    div className = "row" >

        {

            dataSection.map((item, index) => {


                    return (


                            <
                            div className = "col-md-4 col-xs-6 col-sm-3"
                            key = { index } >
                           
                            <Link to={"/view_product/"+item.id}> 
                            <div className = "product" >
                            <div className = "product-img" >
                            < img src = { image_path + item.file_path }
                             alt = "" / >
                            </div> <div className = "product-body" >
                            <p className = "product-category" > Category < /p> <h3 className = "product-name" > < a href = "#" > { item.name } < /a>
                            </h3> {
                                /*<h4 className="product-price">shs 980.00 <del className="product-old-price">shs 990.00</del></h4>
                                                                        <div className="product-rating">
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                            <i className="fa fa-star"></i>
                                                                        </div>
                                                                       <div className="product-btns">
                                                                            <button className="add-to-wishlist"><i className="fa fa-heart-o"></i><span className="tooltipp">add to wishlist</span></button>
                                                                            <button className="add-to-compare"><i className="fa fa-exchange"></i><span className="tooltipp">add to compare</span></button>
                                                                            <button className="quick-view"><i className="fa fa-eye"></i><span className="tooltipp">quick view</span></button>
                                                                        </div> */
                            } </div> {
                            /*<div className="add-to-cart">
                                                                    <button className="add-to-cart-btn"><i className="fa fa-shopping-cart"></i> add to cart</button>
                                                                </div> */
                        } </div> 
                        < /Link>
                        </div > );
                /* /product  */
            })
} {
    (!dataSection.length ? < span className = "col-md-12 product-body" > No devices found < /span> : null)} 
    </div > { /* store botto filter  */ } <div className = "store-filter clearfix" >
        <span className = "store-qty" > { data.length }
        products found </span> <ul className = "store-pagination" > {
            numbers.map((n, i) => ( <
                li className = { `page-item ${currentPage === n ? 'active': '' }` }
                key = { i } >
                <a href = '#'
                className = 'page-link'
                onClick = {
                    (e) => changePage(n, e)
                } > { n } < /a> </li >
            ))
        } <
        li className = "page-item" >
        <
        a href = "#"
        className = "page-link"
        onClick = {
            (e) => nextPage(e)
        } > < i className = "fa fa-angle-right" > < /i> </a >
        </li>

        </ul> < /
        div > { /* /store bottom filter  */ }



        { /* /STORE  */ }

        </div>

    )
}

export default Store_list