import React ,{useState, useEffect, useMemo,createContext} from 'react';
import { Button, Form ,Row,Col,Container, InputGroup } from 'react-bootstrap';
import DataTable , { createTheme } from 'react-data-table-component';
import { useNavigate } from 'react-router-dom'
import  Header from '../../components/Header'
//import Product from './Product'
import Breadcrum from '../../components/Breadcrum'
import Social_media from '../../components/Social_media'
import Footer from '../../components/Footer'
import  customStyles from '../../components/styles/Table_styles'
import  axios_instance from '../../components/api/axiosConfig'
import downloadCSV from '../../components/functionalities/Export'
import CustomLoader from '../../components/functionalities/CustomLoader'
import Product_add_popup from './Product_add_popup'
import Product_edit_popup from './Product_edit_popup'
import Product_delete_popup from './Product_delete_popup'
 
export const AppContextCat = createContext(null)
 
function Product_list_home(){
       
    const [pendingProdt, setPendingProdt] = useState(true);
    const [updateProdt, setUpdateProdt] = useState(true);
    const columns =[
     {
            name:'Actions',
            cell: (row: { id: any }) => (
            <>
            <span  > <AppContextCat.Provider value={setUpdateProdt} ><Product_edit_popup name="Edit data" id={row.id} /></AppContextCat.Provider> </span>{'     '}
            <span  ><Product_delete_popup id={row.id} />
            </span>
            </>
        ),

        ignoreRowClick: true,
        allowOverflow: true,
        button: true,

    },
    {
        name:'Name',
        selector: row => row.name,
        sortable: true,
        },
        {
         name:'Description',
        selector: row => row.description,
        sortable: true,
        },
        {
         name:'Picture',
        selector: row => row.file_path,
        sortable: true,
        },
        {
         name:'Price',
        selector: row => row.price,
        sortable: true,
        },
        
        {
         name:'Product type',
        selector: row => row.producttype,
        sortable: true,
        },
        {
         name:'Rating',
        selector: row => row.rating,
        sortable: true,
        },
        {
         name:'Discount',
        selector: row => row.discount,
        sortable: true,
        },
        {
         name:'Discount percentage',
        selector: row => row.discount_percentage,
        sortable: true,
        },
        {
         name:'Storage',
        selector: row => row.product_storage,
        sortable: true,
        },
        {
         name:'Ram',
        selector: row => row.ram,
        sortable: true,
        },
        {
         name:'OS',
        selector: row => row.os,
        sortable: true,
        },
        {
         name:'Modal',
        selector: row => row.modal,
        sortable: true,
        },
        {
         name:'Update time',
        selector: row => row.updated_at,
        sortable: true,
        },
        {
         name:'Creation date',
        selector: row => row.created_at,
        sortable: true,
        }
        
     ];
     const [dataProdt, setDataProdt] = useState([]);
      const [filteredDataProdt, setFilteredDataProdt] = useState(dataProdt); 
    
    



    async function getDataProdt(){
       
        try{
            await axios_instance({
                url:'api/productlist',
                method:"get"
            }).then( (res)=>{
                console.log(res.data)
                setDataProdt(res.data);
                setFilteredDataProdt(res.data);
                setPendingProdt(false);
            })
        } catch(e){
            console.log(e)
        }
        
    }
    
     useEffect( ()=>{
            getDataProdt()
            console.log("API CALLED")
        },[updateProdt])
    function prodFilterHandle(event){
        console.log(dataProdt);
        console.log(event.target.value);
         if(event.target.value.length > 1){
            const newDataProd = dataProdt.filter(row => {
                return row.name.toLowerCase().includes(event.target.value.toLowerCase())
            });
            setFilteredDataProdt(newDataProd);
        }else{
            setFilteredDataProdt(dataProdt );
            console.log(dataProdt);
        }
       
        
    }
    // CSV exporter
    const filename ='Product list.csv'
    const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)} className="col-sm-4" size="sm">Export</Button>;
    const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(dataProdt,filename)} />, []);
    
    const subHeaderComponentMemo = useMemo(() => {
        return (
            <Container>
              <Row>
                <Col sm={2}><Product_add_popup name="Add Item"/></Col>
                <Col md={{ span: 4, offset: 6 }}><Form.Control className="col-sm-4" size="sm" type="text" placeholder="search" onChange={prodFilterHandle}  /></Col>
              </Row>
            </Container>
        );
    }, []);
  return(
    <div>
    
      <Header/>
      
      <Breadcrum />
      { /* ASIDE */}
      <div class="section">
            { /* container */}
            <div class="container">
                { /* row */}
                <div class="row">
                    <Row>
                       
                       
                        
                    </Row>
                    
                    <DataTable striped bordered hover
                    columns={columns} 
                    data={filteredDataProdt}
                    title ="Item list"
                        selectableRows
                        fixedHeader="true"
                        fixedHeaderScrollHeight="500px"
                        pagination
                        actions={actionsMemo}
                        customStyles={customStyles}
                        progressPending={pendingProdt}
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
            
                        progressComponent={<CustomLoader />}
                    ></DataTable>
                    
                </div> 
                { /* row */}
            </div>
            { /* container */}
      </div>
      { /* ASIDE */}
      <Social_media />
      <Footer />
     
    </div>
    
  )
}

export default Product_list_home
