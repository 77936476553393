import { useState } from 'react';
import {Button, Modal,Col, Row, InputGroup, Form} from 'react-bootstrap';
import  axios_instance from '../../components/api/axiosConfig'

function Add_popup(props) {
  
  const [showCat, setShowCat] = useState(false);
  const handleClose = () => setShowCat(false);
  const handleShow = () => setShowCat(true);
  const [validatedCat, setValidatedCat] = useState(false);
  const formdataAddCat = new FormData()

  async function  handleSubmit(event){
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
        setValidatedCat(true);
      event.preventDefault();
      
      formdataAddCat.append('name',event.target.elements.category_name.value)
      try{ await axios_instance({
            url: "api/cat_add",
            method: "POST",
            data:formdataAddCat
        }).then( (res) =>{
            setShowCat(false)
        })
          
      }
      catch(e){
          console.error(e)
      } 
    }

    
  };
  return (
    <>
      
      <Button size="sm" variant="outline-success" className="add_btn" onClick={handleShow} >+</Button>
      
      <Modal
        show={showCat}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      <Form noValidate validated={validatedCat} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Category</Form.Label>
                      <Form.Control type="text" name="category_name" placeholder="Phone, accesories,Extensions" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" >Add</Button>
        </Modal.Footer>
        </Form>
      </Modal>
      
    </>
  );
}

export default Add_popup;