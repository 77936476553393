import React ,{useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import axios_instance from '../../components/api/axiosConfig'
import {Col, Row, Container, Card,Button,Form,Modal} from 'react-bootstrap';


function Login_popup()
{
 
const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] =useState("")
  const [password, setPassword] = useState("")
  const navigate  = useNavigate()
  /*useEffect(()=>{
    if(localStorage.getItem('user-info')){
      navigate('/category_list')
    }else{
        navigate('/login')
    }
    refEmail.current.value ='';
    refps.current.value ='';
  },[])*/
  // -token:'{{csrf_token()}}'
  async function login(){
      
      let data ={email, password}
      console.log(data)
      try{
          await axios_instance({
             url:'/api/login',
             method:"POST",
             data:data,
             
          }).then( (res)=>{
              console.log(res.data)
             let result =  res.data
             
             localStorage.setItem('user-info',JSON.stringify(result))
             navigate('/category_list')
          })
      }
      catch(e){
          console.error(e)
      }
      
  }
  return (
    <>
    
      
      {/*<Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>*/}
      <span onClick={handleShow}> Login</span>
      <Modal show={show} onHide={handleClose}>
        {/*<Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>*/}
        {/*<Modal.Body>*/}
          <Form>
                
                        <Row className=" d-flex ">
                          <Col  xs={12}>
                            <div className="border border-3 border-primary"></div>
                            <Card className="shadow">
                              <Card.Body>
                                <div className="mb-3 mt-md-4">
                                  <h2 className="fw-bold mb-2 text-uppercase ">Brand</h2>
                                  <p className=" mb-5">Please enter your login and password!</p>
                                  <div className="mb-3">
                                    <Form>
                                      <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label className="text-center">
                                          Email address
                                        </Form.Label>
                                        <Form.Control type="email"  autocomplete="off"   onChange={ (e)=>setEmail(e.target.value) }/>
                                      </Form.Group>

                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPassword"
                                      >
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password"  autocomplete="off"   onChange={ (e)=>setPassword(e.target.value) } />
                                      </Form.Group>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicCheckbox"
                                      >
                                        
                                      </Form.Group>
                                      <div className="d-grid">
                                        <Button variant="primary" onClick={login} >
                                          Login
                                        </Button>
                                      </div>
                                    </Form>
                                   
                                  </div>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                                  
          </Form>
       { /*</Modal.Body>*/}
        {/*<Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>*/}
      </Modal>
    </>
  );
}

export default Login_popup