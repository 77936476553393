import { useState } from 'react';
import { Navbar, Nav , NavDropdown,Form } from 'react-bootstrap'
import { Link, useNavigate,useLocation  } from 'react-router-dom'
import Login_popup from '../pages/login_report/Login_popup';
import '../components/styles/NavbarStyles.css';
function Header()
{
  let userinfo=''
  let username =''
  const navigate =useNavigate()
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  if(localStorage.getItem('user-info')){
    userinfo= JSON.parse(localStorage.getItem('user-info'))
    username = userinfo.name
   
  }

  function Logout_handle(){
    localStorage.clear()
    navigate('/')
  }
  const [mobileMode,setMobileMode] = useState(true);
  
  function onClickHandle(){
      
      setMobileMode(!mobileMode );
  }
  const Logout = () => <span onClick={Logout_handle} >Logout</span>;
  return(
  
        <>
            <nav className="nav_container">
                <div>
                <a>
                    <img height="50px" src="./img/logo.png" alt="" />
                </a>
                </div>
                <div >
                    <ul className={mobileMode?"nav_bar  active" :"nav_bar"} >
                    {
                    localStorage.getItem('user-info')?
                    <>
                        <li> <Link to="/product_list" className={splitLocation[1] === "product_list" ? "active" : ""} >Products </Link>  </li>
                        <li><Link to="/category_list" className={splitLocation[1] === "category_list" ? "active" : ""} >Categories </Link>  </li>
                        </>
                    :
                    <>
                    
                    </>}
                        {/*<li><a href="index.html">Smartphones</a> </li>
                         <li><a href="index.html">Acessories</a> </li>
                        <li><a href="index.html">Contacts</a> </li>*/}
                         <li><span>{/*<i className="fa fa-user-o"></i> */} {(localStorage.getItem('user-info'))? <Logout /> :  <Login_popup /> }</span> </li>
                    </ul>
                </div>
                
                <div id="mobile">
                {/*fa fa-bars fa fa-times*/}
                    <i id="bar" className={mobileMode?"fa fa-bars":"fa fa-times"} onClick={onClickHandle}></i>
                </div>
            </nav>   
        
     
        </>
  )
}

export default Header