import { useState,useEffect, useRef, useContext } from 'react';
import {Button, Modal,Col, Row, InputGroup, Form} from 'react-bootstrap';
import  axios_instance,{main_url} from '../../components/api/axiosConfig'
//import {AppContextCat} from  './Category_list_home'

const Product_edit_popup =(props)=> {
   //const { setUpdateCat } = useContext(AppContextCat);
  const [showProdEdit, setShowProdEdit] = useState(false);
  const handleClose = () => setShowProdEdit(false);
  
  const [validatedProdEdit, setValidatedProdEdit] = useState(false);
  const [dataEditProd, setDataEditProd] = useState([])
  /*const [nameEditCat,setNameEditCat] = useState('')*/
 // const [ProdImageEdit,setProdImageEdit]= useState('');
  //const nameEditRefCat =useRef(null);

  const [editProd_name,seteditProdName] = useState('')
  const [editProd_description,seteditProdDescription] = useState('')
  const [editProd_file_path,seteditProdFile_path] = useState('')
  const [editProd_price,seteditProdPrice] = useState('')
  const [editProd_producttype,seteditProdProducttype] = useState('')
  const [editProd_rating,seteditProdRating] = useState('')
  const [editProd_discount,seteditProdDiscount] = useState('')
  const [editProd_discount_percentage,seteditProdDiscount_percentage] = useState('')
  const [editProd_product_storage,seteditProdProduct_storage] = useState('')
  const [editProd_ram,seteditProdRam] = useState('')
  const [editProd_os,seteditProdOs] = useState('')
  const [editProd_modal,seteditProdModal] = useState('')


 
  const id = props.id

  const handleShow = () =>{
     getDataEditProd() 
     setShowProdEdit(true); 
     
  } 
         

    async function getDataEditProd(){
        try{
            await axios_instance({
                url:'api/getproduct/'+id,
                method:"get"
            }).then( (res)=>{
                //console.log(res.data)
                setDataEditProd(res.data)
                
            })
        } catch(e){
            console.log(e)
        }
        
    }
  async function  handleSubmit(event){
    const form = event.currentTarget;
    const formdataEditProd = new FormData()
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
        setValidatedProdEdit(true);
      event.preventDefault();
      
      /*formdataEditProd.append('name',event.target.elements.category_name.value)*/
      /*console.log(nameEditRefCat.current.value);*/
      formdataEditProd.append('name', ( (editProd_name)? editProd_name : dataEditProd.name ) )
      formdataEditProd.append('description', ( (editProd_description)? editProd_description : dataEditProd.description ) )
      formdataEditProd.append('file', ( (editProd_file_path)? editProd_file_path : dataEditProd.file_path ) )
      formdataEditProd.append('price', ( (editProd_price)? editProd_price : dataEditProd.price ) )
      
      formdataEditProd.append('producttype', ( (editProd_producttype)? editProd_producttype : dataEditProd.producttype ) )
      formdataEditProd.append('rating', ( (editProd_rating)? editProd_rating : dataEditProd.rating ) )
      formdataEditProd.append('discount', ( (editProd_discount)? editProd_discount : dataEditProd.discount ) )
      formdataEditProd.append('discount_percentage', ( (editProd_discount_percentage)? editProd_discount_percentage : dataEditProd.discount_percentage ) )
      formdataEditProd.append('product_storage', ( (editProd_product_storage)? editProd_product_storage : dataEditProd.product_storage ) )
      formdataEditProd.append('ram', ( (editProd_ram)? editProd_ram : dataEditProd.ram ) )
      formdataEditProd.append('os', ( (editProd_os)? editProd_os : dataEditProd.os ) )
      formdataEditProd.append('modal', ( (editProd_modal)? editProd_modal : dataEditProd.modal ) )
      
      try{ await axios_instance({
            url: "api/updateproduct/"+id,
            method: "POST",
            data:formdataEditProd
        }).then( (res) =>{
            
            setShowProdEdit(false)
           // setUpdateCat(false)
            
        })
        window.location.reload()    
      }
      catch(e){
          console.error(e)
      } 
      
    }

    
  };
  return (
    <>
      
      
      <i className="fa fa-edit  action_btn action_edit " onClick={handleShow}></i>
      <Modal
        show={showProdEdit}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
      <Form noValidate validated={validatedProdEdit} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                  
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03" className="mb-3">
                      <Form.Label>Item name</Form.Label>
                      <Form.Control type="text" name="name" onChange={(e)=> seteditProdName(e.target.value)} placeholder="samsung, nokia, item" defaultValue={dataEditProd.name} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                      <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Description</Form.Label>
                      <Form.Control type="text" name="description" onChange={(e)=> seteditProdDescription(e.target.value)} placeholder="color, used..." defaultValue={dataEditProd.description} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row >
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Operating System</Form.Label>
                      <Form.Control type="text" name="os" placeholder="" onChange={(e)=> seteditProdOs(e.target.value)} defaultValue={dataEditProd.os} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Price</Form.Label>
                      <Form.Control type="text" name="price" onChange={(e)=> seteditProdPrice(e.target.value)} placeholder="" defaultValue={dataEditProd.price} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Product type</Form.Label>
                      <Form.Control type="text" name="producttype" onChange={(e)=> seteditProdProducttype(e.target.value)} placeholder="Phone, accessory,earphone...." defaultValue={dataEditProd.producttype} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Rating</Form.Label>
                      <Form.Control type="text" name="rating" onChange={(e)=> seteditProdRating(e.target.value)} defaultValue={dataEditProd.rating} placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Discount percentage</Form.Label>
                      <Form.Control type="text" name="discount_percentage" onChange={(e)=> seteditProdDiscount_percentage(e.target.value)} defaultValue={dataEditProd.discount_percentage} placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Discount price</Form.Label>
                      <Form.Control type="text" name="discount" onChange={(e)=> seteditProdDiscount(e.target.value)} defaultValue={dataEditProd.discount} placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                  <Row >
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Storage</Form.Label>
                      <Form.Control type="text" name="product_storage" onChange={(e)=> seteditProdProduct_storage(e.target.value)} defaultValue={dataEditProd.product_storage} placeholder="" required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Ram</Form.Label>
                      <Form.Control type="text" name="ram" placeholder="" onChange={(e)=> seteditProdRam(e.target.value)} defaultValue={dataEditProd.ram} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
                  <Form.Group as={Col} md="6" controlId="validationCustom03">
                      <Form.Label>Product model</Form.Label>
                      <Form.Control type="text" name="modal" placeholder="" onChange={(e)=> seteditProdModal(e.target.value)}
 defaultValue={dataEditProd.modal} required />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                  <Row className="mb-12">
                  <Form.Group as={Col} md="12" controlId="validationCustom03" >
                      <Form.Label>Photo</Form.Label>
                     <Form.Control type="file" name="file_path" placeholder="color, used..."  defaultValue={dataEditProd.file_path}  onChange={(e)=>seteditProdFile_path(e.target.files[0])} />
                     <img style={{width:200}} src={main_url+dataEditProd.file_path}  alt="No photo"/>
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid category.
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                   
                  </Row>
      </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit"  onClick={handleSubmit}>Edit</Button>
        </Modal.Footer>
        </Form>
      </Modal>
      
    </>
  );
}

export default Product_edit_popup;